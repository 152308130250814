import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import router from './router'
import store from './store'
import messageData from "./components/layout/messageData.vue";
//Swiper3
import '@/assets/swiper/swiper.css'
import '@/assets/scss/base.scss'
import * as qiniu from 'qiniu-js'
import axios from 'axios'
import qs from 'qs'

// import
import VueAwesomeSwiper from 'vue-awesome-swiper'
// mount with global
Vue.use(VueAwesomeSwiper)

Vue.prototype.MessageDataMain = messageData
Vue.prototype.allCloseMesge = function () {
  messageData.globalVarMess = false
}


Vue.prototype.$axios = axios
Vue.prototype.$qs = qs
Vue.prototype.$qiniu = qiniu
Vue.use(ElementUI);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
