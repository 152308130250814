import Vue from 'vue';
import axios from "axios";

// const testURL = 'https://2d574b.r6.cpolar.top/wrste/api'
// const baseURL = 'https://api.wlhex.com:881'

// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || ''
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'

const api = axios.create({
  //基础路径
  baseURL: '', // 所有请求的公共地址部分
  crossDomain: true,
  timeout: 5000, // 请求超时时间,这里的意思是当请求时间超过5秒还未取得结果时,提示用户请求超时
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    "Access-Control-Allow-Headers":"Authorization,Origin, X-Requested-With, Content-Type, Accept"
  },
  proxy: {
    '/wrste': {
      target: 'https://api.wlhex.com:881',// 你要请求的后端接口ip+port
      changeOrigin: true,// 允许跨域，在本地会创建一个虚拟服务端，然后发送请求的数据，并同时接收请求的数据，这样服务端和服务端进行数据的交互就不会有跨域问题
      ws: true,// 开启webSocket
      secure: false, 
      pathRewrite: {
        '^/api': '',// 替换成target中的地址
      }
    }
  }
})

// 请求拦截处理 请求拦截 在请求拦截中可以补充请求相关的配置
// interceptors axios的拦截器对象
api.interceptors.request.use(config => {
  // config 请求的所有信息
  // console.log('config',config.url);
  const reqConfig = { ...config }
  if (localStorage.getItem('token')) {
    reqConfig.headers.token = localStorage.getItem('token');
  }
  // 响应成功的返回
  return reqConfig // 将配置完成的config对象返回出去 如果不返回 请求讲不会进行
}, err => {
  // 请求发生错误时的相关处理 抛出错误
  //  //响应失败的返回
  Promise.reject(err)
})

//响应拦截处理  响应拦截器：包含两个函数（一个是成功返回的函数，一个是失败的返回的函数）
api.interceptors.response.use(res => {
  // 我们一般在这里处理，请求成功后的错误状态码 例如状态码是500，404，403
  // res 是所有相应的信息
  // console.log(res)
  if (res.data.status !== 1) {
    switch (res.data.status) {
      case -1:
        Vue.prototype.$message({
          message: '鉴权失败，token错误',
          type: 'error'
        })
        window.location.href = '/login'
        break;
      case -2:
        Vue.prototype.$message({
          message: '请求频繁',
          type: 'error',
        })
        break;
      case -3:
        Vue.prototype.$message({
          message: 'token错误',
          type: 'error',
        })
        break;
      case -4:
        Vue.prototype.$message({
          message: 'token为空',
          type: 'error'
        })
        break;
      case -5:
        Vue.prototype.$message({
          message: '识别错误',
          type: 'error'
        })
        break;
      case -6:
        Vue.prototype.$message({
          message: '服务器繁忙',
          type: 'error'
        })
        break;
      case -7:
        Vue.prototype.$message({
          message: 'image错误',
          type: 'error'
        })
        break;
      case -8:
        Vue.prototype.$message({
          message: '次数用尽',
          type: 'error'
        })
        break;
      case -9:
        Vue.prototype.$message({
          message: '账户异常',
          type: 'error'
        })
        break;
      case -10:
        Vue.prototype.$message({
          message: '其他异常',
          type: 'error'
        })
        break;
      case -11:
        Vue.prototype.$message({
          message: '缺少账号',
          type: 'error'
        })
        break;
      
      
      case -18:
        Vue.prototype.$message({
          message: '验证码超时',
          type: 'error'
        })
        break;
      case -19:
        Vue.prototype.$message({
          message: '验证码2分钟有效,请稍后再试!!!!',
          type: 'error'
        })
        break;
      case -20:
        Vue.prototype.$message({
          message: '您短信条数已经到上限，或检查是否被通讯商拉黑!',
          type: 'error'
        })
        break;
      case -21:
        Vue.prototype.$message({
          message: '机器码参数为null',
          type: 'error'
        })
        break;
      case -22:
        Vue.prototype.$message({
          message: '找回密码错误',
          type: 'error'
        })
        break;
      case -23:
        Vue.prototype.$message({
          message: '验证码已过期或错误',
          type: 'error'
        })
        break;
    
      default:
        break;
    }
  } 
  return res.data
  
}, err => {
  // 服务器响应发生错误时的处理
  Promise.reject(err)
})

//最后一步 暴露出去实例导出
export default api