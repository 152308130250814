import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/views/index.vue'
import Login from "@/views/login.vue"
import SignUp from "@/views/signup.vue"
// import Consum from "@/views/SignUp.vue"
import fileList from "@/views/fileList.vue"
// import uploadFile from "@/views/uploadFile.vue"
import transRecord from "@/views/transRecord.vue"
import orderList from "@/views/orderList.vue"
import Pay from "@/views/payCon.vue";


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: SignUp
  },
  {
    path: '/fileList',
    name: 'fileList',
    component: fileList
  },
  {
    path: '/transRecord',
    name: 'transRecord',
    component: transRecord
  },
  // {
  //   path: '/uploadFile',
  //   name: 'uploadFile',
  //   component: uploadFile
  // },
  {
    path: '/order',
    name: 'orderList',
    component: orderList
  },
  {
    path: '/pay',
    name: 'Pay',
    component: Pay
  },
]

const originalPush = VueRouter.prototype.push

const originalReplace = VueRouter.prototype.replace

VueRouter.prototype.push = function push(location){
  return originalPush.call(this , location).catch(err=>err)
}

VueRouter.prototype.replace = function replace(location){
  return originalReplace.call(this , location).catch(err=>err)
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
