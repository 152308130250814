export const languageList = [
  {
    value: 'ChinesePRC',
    label: '中+英文'
  },
  {
    value: 'ChineseTaiwan',
    label: '繁中+英文'
  },
  {
    value: 'English',
    label: '英语'
  },
/*  {
    value: 'Japanese',
    label: '日语'
  },*/
  {
    value: 'German',
    label: '德语'
  },
  {
    value: 'Russian',
    label: '俄语'
  },
  {
    value: 'French',
    label: '法语'
  },
/*  {
    value: 'Korean',
    label: '韩语'
  },*/
  {
    value: 'PortugueseStandard',
    label: '葡萄牙语'
  },
  {
    value: 'Spanish',
    label: '西班牙语'
  },
  {
    value: 'Italian',
    label: '意大利语'
  },
]

export const translateLanguageList = [
  {
    value: 'zh',
    label: '中文'
  },
  {
    value: 'en',
    label: '英语'
  },
  {
    value: 'yue',
    label: '粤语'
  },
  {
    value: 'jp',
    label: '日语'
  },
  {
    value: 'kor',
    label: '韩语'
  },
  {
    value: 'fra',
    label: '法语'
  },
  {
    value: 'spa',
    label: '西班牙语'
  },
  {
    value: 'th',
    label: '泰语'
  },
  {
    value: 'ara',
    label: '阿拉伯语'
  },
  {
    value: 'ru',
    label: '俄语'
  },
  {
    value: 'pt',
    label: '葡萄牙语'
  },
  {
    value: 'de',
    label: '德语'
  },
  {
    value: 'it',
    label: '意大利语'
  },
  {
    value: 'el',
    label: '希腊语'
  },
  {
    value: 'nl',
    label: '荷兰语'
  },
  {
    value: 'pl',
    label: '波兰语'
  },
  {
    value: 'bul',
    label: '保加利亚语'
  },
  {
    value: 'est',
    label: '爱沙尼亚语'
  },
  {
    value: 'dan',
    label: '丹麦语'
  },
  {
    value: 'fin',
    label: '芬兰语'
  },
  {
    value: 'cs',
    label: '捷克语'
  },
  {
    value: 'rom',
    label: '罗马尼亚语'
  },
  {
    value: 'slo',
    label: '斯洛文尼亚语'
  },
  {
    value: 'swe',
    label: '瑞典语'
  },
  {
    value: 'hu',
    label: '匈牙利语'
  },
  {
    value: 'cht',
    label: '繁体中文'
  },
  {
    value: 'vie',
    label: '越南语'
  }
]

export const sectorList = [
  {
    value: 'General',
    label: '通用'
  },
  {
    value: 'electronics',
    label: '电子科技'
  },
  {
    value: 'finance',
    label: '金融财经'
  },
  {
    value: 'finance1',
    label: '金融财经(英)'
  },
  {
    value: 'mechanics',
    label: '水利机械'
  },
  {
    value: 'medicine',
    label: '生物医药'
  },
  {
    value: 'medicine1',
    label: '生物医药(英)'
  },
  {
    value: 'novel',
    label: '网络文学'
  }
]

export const outFileTypeList = [
  {
    value: 'docx',
    label: 'docx'
  },
  {
    value: 'xlsx',
    label: 'xlsx'
  },
  {
    value: 'xls',
    label: 'xls'
  },
  {
    value: 'ppt',
    label: 'ppt'
  },
  {
    value: 'txt',
    label: 'txt'
  },
  {
    value: 'xps',
    label: 'xps'
  },
  {
    value: 'html',
    label: 'html'
  },
]