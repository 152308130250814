<template>
  <div class="consum">
    <Header></Header>
    <div class="container tran-box">
      <el-button 
        class="refresh_button"
        type="primary" 
        icon="el-icon-refresh" 
        @click="getTableData">
        刷新文件状态
      </el-button>
      <el-table
        :data="tableData"
        v-loading="loading"
        style="width: 100%">
        <template slot="empty">
          <div>
            <img width="200" height="200" src="@/assets/images/no_data.png" alt="">
            <p>还没有文件记录哦，快去
              <router-link to="/">翻译</router-link>
              吧！</p>
          </div>
        </template>
        <el-table-column
          prop="name"
          width="320"
          :show-overflow-tooltip="true"
          label="文档名">
          <template slot-scope="scope">
            <div class="file-name-type flex-align-center">
              <img 
                v-if="scope.row.file_type === 'docx' || scope.row.file_type === 'doc'"
                src="@/assets/images/type/word.png" alt="">
              <img 
                v-if="scope.row.file_type === 'xlsx' || scope.row.file_type === 'xls' "
                src="@/assets/images/type/excel.png" alt="">
              <img 
                v-if="scope.row.file_type === 'ppt' || scope.row.file_type === 'pptx'"
                src="@/assets/images/type/ppt.png" alt="">
              <img v-if="scope.row.file_type === 'pdf'" src="@/assets/images/type/pdf.png" alt="">
              <img v-if="scope.row.file_type === 'html'" src="@/assets/images/type/html.png" alt="">
              <span>{{ scope.row.file_name }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          width="320"
          :show-overflow-tooltip="true"
          label="输出文件">
          <template slot-scope="scope">
            <div class="file-name-type flex-align-center">
              <img v-if="scope.row.out_file_type === 'docx'" src="@/assets/images/type/word.png" alt="">
              <img v-if="scope.row.out_file_type === 'xlsx'" src="@/assets/images/type/excel.png" alt="">
              <img v-if="scope.row.out_file_type === 'ppt'" src="@/assets/images/type/ppt.png" alt="">
              <img v-if="scope.row.out_file_type === 'pdf'" src="@/assets/images/type/pdf.png" alt="">
              <img v-if="scope.row.out_file_type === 'html'" src="@/assets/images/type/html.png" alt="">
              <img v-if="scope.row.out_file_type === 'xps' || scope.row.out_file_type === 'txt'" src="@/assets/images/type/txt.png" alt="">
              <span>{{ scope.row.out_file }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="pages"
          width="80"
          label="页数">
          <template slot-scope="scope">
            <div>{{ scope.row.file_pages }}页</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="language"
          width="180"
          :show-overflow-tooltip="true"
          label="翻译语言">
          <template slot-scope="scope">
            <div>
              {{ scope.row.ocr_language_text }} → {{ scope.row.translate_language_text }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="mode"
          width="180"
          :show-overflow-tooltip="true"
          label="行业">
          <template slot-scope="scope">
            <div>
              {{ scope.row.field_mode_text }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="create_time"
          width="200"
          :show-overflow-tooltip="true"
          label="上传时间">
          <template slot-scope="scope">
            <div>
              {{ scope.row.create_time }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="status"
          :show-overflow-tooltip="true"
          label="文件状态">
          <template slot-scope="scope">
            <div class="file-status">
              <div class="flex-align-center">
                <i class="el-icon-success" style="color: #67C23A" v-if="scope.row.file_state === 8"></i>
                <i class="el-icon-error" style="color: #F56C6C" v-if="scope.row.file_state === 4 || scope.row.file_state === 5 || scope.row.file_state == 6 || scope.row.file_state == 7"></i>
                <i class="el-icon-warning" style="color: #E6A23C" v-if="scope.row.file_state === 0 || scope.row.file_state === 1 || scope.row.file_state == 2 || scope.row.file_state == 3"></i>
                <p style="margin-left: 5px">{{ scope.row.statusText }}</p>
              </div>
              <p style="margin-top: 5px">{{ scope.row.createTime }}</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          width="100">
          <template slot-scope="scope">
            <el-button 
              v-if="scope.row.file_state === 8"
              @click="handleClick(scope.row)" 
              type="text" 
              size="small">
              下载
            </el-button>
            <el-button @click="deleteClick(scope.row)" type="text" size="small">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="pagination"
        @current-change="handleCurrentChange"
        :pager-count="11"
        :page-size="pageSize"
        layout="total, prev, pager, next"
        :total="total">
      </el-pagination>
    </div>
    <!-- <div id="download">
      <el-input
        type="textarea"
        placeholder="请输入内容"
        v-model="textarea">
      </el-input>
    </div> -->
  </div>
</template>

<script>
// deleteUserFile
import {deleteUserFile, findUserFileList, getDownloadFile} from "@/api/api.js";
import {languageList, sectorList, translateLanguageList} from "@/assets/util/array.js";
import Header from "@/components/layout/Header.vue";
import Util from "@/assets/util/util.js";

export default {
  components: {
    Header
  },
  data() {
    return {
      token: localStorage.getItem('token'),
      tableData: [],
      pageOffset: 1,
      pageSize: 10,
      total: 10,
      loading: true,
      downloadLink: '',
      currentPage4: 4,
      textarea: '',
      languageList: languageList,
      translateLanguageList: translateLanguageList,
      sectorList: sectorList
    }
  },
  created () {
    this.getTableData()
    this.findArray()
  },
  methods: {

    dataTable (data) {
      data.forEach((item) => {
        if (item.create_time) {
          var time = Math.round(Number(item.create_time) / 1000)
          item.create_time = Util.filterTimeYmdHms(time)
        }
        if (item.ocr_language) {
          const ocr_language_text = this.languageList.find(element => element.value === item.ocr_language)
          item.ocr_language_text = ocr_language_text.label
        }
        if (item.translate_language) {
          const translate_language_text = this.translateLanguageList.find(element => element.value === item.translate_language)
          item.translate_language_text = translate_language_text.label
        }
        if (item.field_mode) {
          const field_mode_text = this.sectorList.find(element => element.value === item.field_mode)
          item.field_mode_text = field_mode_text.label
        }
        if (item.file_name) {
          item.file_type = item.file_name.split('.')[1]
        }
        
        switch (item.file_state) {
          case 0:
            item.statusText = '创建文件'
            break;
          case 1:
            item.statusText = '上传成功'
            break;
          case 2:
            item.statusText = '进入处理队列'
            break;
          case 3:
            item.statusText = '开始处理'
            break;
          case 4:
            item.statusText = '次数不够'
            break;
          case 5:
            item.statusText = '翻译模型与翻译语言不匹配'
            break;
          case 6:
            item.statusText = '文件错误'
            break;
          case 7:
            item.statusText = '服务器繁忙'
            break;
          case 8:
            item.statusText = '翻译完成'
            break;
        
          default:
            break;
        }
      })
    },
    getTableData () {
      this.loading = true
      findUserFileList(this.pageOffset, this.pageSize).then((res) => {
        this.loading = false
        if (res.status === 1) {
          const data = res.data.content;
          // this.tableData = res.data.content
          this.dataTable(data)
          this.tableData = [...data]
          this.total = res.data.totalElements
        }
      })
    },
    handleClick (row) {
      console.log('row', row)
      getDownloadFile(row.out_file).then((res) => {
        if (res.status === 1) {
          let url = res.data
          this.downloadLink = url
          // console.log('url', url)
          if (row.out_file_type === 'html') {
            this.export_raw(row.out_file, url)
          } else {
            let link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download',row.out_file)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          }
        }
      })
    },
    fake_click (obj) {
      var ev = document.createEvent("MouseEvents");
      ev.initMouseEvent(
        "click", true, false, window, 0, 0, 0, 0, 0
        , false, false, false, false, 0, null
        );
      obj.dispatchEvent(ev);
    },
    export_raw (name, url) {
      var oReq = new XMLHttpRequest()
      oReq.open("GET", url, true)
      oReq.responseType = "blob"
      oReq.onload = function(oEvent) {
        var content = oReq.response;
        var elink = document.createElement("a");
        elink.download = name;
        elink.style.display = "none";
        var blob = new Blob([content]);
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        document.body.removeChild(elink);
      };
      oReq.send();
    },

    deleteClick (row) {
      this.tableData.splice(this.tableData.indexOf(row), 1)
      deleteUserFile(row.id, this.token ).then((res) => {
        if (res.status === 1) {
          if (!this.MessageDataMain.globalVarMess) {
            this.MessageDataMain.globalVarMess = true
            this.$message({
              message: '删除成功',
              type: 'error',
              onClose: this.allCloseMesge
            })
          }
          if (this.tableData.length !== 0) {
            this.getTableData()
          }
          if (this.tableData.length === 0 && this.pageOffset !== 1) {
            var currentPage = this.pageOffset -1
            this.handleCurrentChange(currentPage)
          }
        }
      })
      
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
      this.pageOffset = val
      this.getTableData()
    },
    // 查找数组中某一项
    findArray () {
      return this.languageList.find(element => element.value === 'ChinesePRC')
    }
  }
}
</script>

<style lang="scss" scoped>
.file-name-type {
  img {
    width: 15px;
    height: 15px;
    margin-right: 5px;
  }
}
.refresh_button.el-button {
  position: absolute;
  top: 15px;
  right: 20px;
}
</style>