<template xmlns="http://www.w3.org/1999/html">
  <div class="tran-box container">
    <!-- v-if="!FileUploaded" -->
    <div class="tran-doc-box flex-center flex-align-center"  >
      <el-upload
        class="upload-demo"
        ref="upload"
        action=""
        :on-remove="handleRemove"
        :accept="fileAccept"
        :file-list="tableData"
        :on-change="UploadChange"
        :before-upload="BeforeUnload"
        :on-progress="handleProgress"
        :show-file-list="false"
        drag
        multiple
        :auto-upload="false"
        >
        <!-- <el-button slot="trigger" size="small" type="primary">选取文件</el-button> -->
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">选取文件</div>
        <!-- <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">上传到服务器</el-button> -->
        <div class="accept-type flex-align-center flex-center">
          <span style="color: #000">支持13种格式：</span>
          <el-tooltip style="margin-right: 10px" class="item" effect="dark" content=".doc, .docx" placement="bottom">
            <img style="width: 22px; height: 22px;" src="@/assets/images/accept/Word.png" alt="">
          </el-tooltip>
          <el-tooltip style="margin-right: 10px" class="item" effect="dark" content=".xls, .xlsx" placement="bottom">
            <img style="width: 22px; height: 22px;" src="@/assets/images/accept/xlsm.png" alt="">
          </el-tooltip>
          <el-tooltip style="margin-right: 10px" class="item" effect="dark" content=" .ppt, .pptx" placement="bottom">
            <img style="width: 22px; height: 22px;" src="@/assets/images/accept/pptx.png" alt="">
          </el-tooltip>
          <el-tooltip style="margin-right: 10px" class="item" effect="dark" content=".pdf" placement="bottom">
            <img style="width: 22px; height: 22px;" src="@/assets/images/accept/PDF1.png" alt="">
          </el-tooltip>
          <!-- <el-tooltip style="margin-right: 10px" class="item" effect="dark" content=".htm, .html" placement="bottom">
            <img style="width: 22px; height: 22px;" src="@/assets/images/accept/html.png" alt="">
          </el-tooltip> -->
          <!-- <el-tooltip style="margin-right: 10px" class="item" effect="dark" content=" .txt, .odt, .rtf" placement="bottom">
            <img style="width: 22px; height: 22px;" src="@/assets/images/accept/txt.png" alt="">
          </el-tooltip> -->
          <el-tooltip class="item" effect="dark" content=".bmp, .gif, .jpg, .jpeg, .png, .tiff" placement="bottom">
            <img style="width: 22px; height: 22px;" src="@/assets/images/accept/picture.png" alt="">
          </el-tooltip>
        </div>
        <span class="el-upload_pdf_a_tips" style="color: #C0C0C0 ">*特别提示：双层pdf/a可以先转为扫描件再翻译，原始文字层不准确会导致翻译结果不准确</span>
      </el-upload>
    </div>
    <!-- v-else -->
    <el-table
      :data="tableData"
      style="width: 100%">
      <el-table-column
        prop="name"
        label="文档名"
        :show-overflow-tooltip="true"
        width="180">
      </el-table-column>
      <el-table-column
        prop="pages"
        label="页数">
      </el-table-column>
      <el-table-column
        prop="sector"
        label="行业">
        <template slot-scope="scope">
          <el-select 
            v-model="scope.row.translate_mode" 
            @change="filterSectorChange(scope.row, scope.row.translate_mode)"
            filterable 
            placeholder="-请选择-">
            <el-option
              v-for="item in sectorList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column
        prop="ocrLanguage"
        label="原文语言">
        <template slot-scope="scope">
          <el-select 
            :disabled="scope.row.isDisabled"
            v-model="scope.row.ocrLanguage" 
            filterable 
            placeholder="-请选择-">
            <el-option
              v-for="item in languageList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column
        prop="translateLanguage"
        label="目标语言">
        <template slot-scope="scope">
          <el-select 
            :disabled="scope.row.isDisabled"
            v-model="scope.row.translateLanguage" 
            filterable 
            placeholder="-请选择-">
            <el-option
              v-for="item in translateLanguageList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column
        prop="outFileType"
        label="输出文件">
        <template slot-scope="scope">
          <el-select 
            v-model="scope.row.outFileType" 
            filterable 
            placeholder="-请选择-">
            <el-option
              v-for="item in outFileTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column
        prop="translate_table"
        label="翻译表格">
        <template slot-scope="scope">
          <el-switch
            active-color="#239656"
            inactive-color="#e86161"
            v-model="scope.row.translate_table"
            >
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        width="140">
        <template slot-scope="scope">
          <div class="flex">
            <el-button 
              v-if="!scope.row.uploadQi"
              @click="uploadQiniu(scope.row)" 
              type="text" 
              size="small" 
              style="margin-left: 5px">
              <span>翻译</span>
            </el-button>
            <el-button 
              v-if="scope.row.uploadQi && !scope.row.file_state"
              type="text" 
              size="small" 
              style="margin-left: 5px">
              <i class="el-icon-upload"></i>
              <span v-if="scope.row.percentage !== 100">上传中{{ scope.row.percentage }}</span>
              <span v-else>等待翻译</span>
            </el-button>
            <el-button 
              v-show="scope.row.file_state === 0 || scope.row.file_state === 1 || scope.row.file_state === 2 || scope.row.file_state === 3"
              type="text" 
              size="small" 
              style="margin-left: 5px">
              <i  class="el-icon-loading"></i>
              <span>{{ scope.row.FileStatus }}</span>
            </el-button>
            <el-button 
              v-show="scope.row.file_state === 4 || scope.row.file_state === 5 || scope.row.file_state === 6 || scope.row.file_state === 7"
              type="text"
              size="small" 
              style="margin-left: 5px; color: #F56C6C;">
              <span v-if="scope.row.file_state === 4">
                次数不够，请 <router-link style="color: #3f66ff" to="/pay">充值</router-link>
              </span>
              <span v-else>{{ scope.row.FileStatus }}</span>
            </el-button>
            <el-button 
              v-show="scope.row.file_state === 8"
              @click="downloadQiniu(scope.row)" 
              type="text"
              size="small" 
              style="margin-left: 5px">
              <span>下载</span>
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import {getUploadFileKey, getDownloadFile, getFileInfo, UploadSucceeded, getUserProfile} from "@/api/api.js";
import { languageList, translateLanguageList, sectorList, outFileTypeList } from "@/assets/util/array.js";
import {mapMutations} from "vuex";
export default {
  name: 'Translate',
  data() {
    return {
      Uploading: false,
      FileUploaded: false,
      percentage: 0,
      ocrLanguage: '中文',
      translateLanguage: '英语',
      translateMode: '通用',
      fileAccept: '.doc, .docx, .pdf,.pptx,.ppt,.xls,.xlsx,.bmp, .gif, .jpg, .jpeg, .png, .tiff',
      userInfo: '',
      sectorList: sectorList,
      languageList: languageList,
      translateLanguageList: translateLanguageList,
      outFileTypeList: outFileTypeList,
      isDisabled: false,
      downloadLink: '',
      qiniuToken: '',
      tableData: [],
      fileName: '',
      FileStatus: '',
      uploadCompleteFile: {},
      storageFile: {},
      timer: null,
      outFileName: ''
    }
  },
  created() {

  this.userInfo=JSON.parse(localStorage.getItem('userInfo')).userInfo;

  if(this.userInfo===null){
    getUserProfile().then((res) => {
      console.log('res', res)
      if (res.status === 1) {
        // this.userInfo = res.data
        // this.token = res.data.token
        this.setUserInfo(res.data)
        // localStorage.setItem('token', res.data.token);
        localStorage.setItem('userInfo', JSON.stringify(res.data));
        this.userInfo=JSON.parse(localStorage.getItem('userInfo')).userInfo;
      }
    })
  }
    console.log('userInfo', this.userInfo)
  },
  methods: {
    ...mapMutations(['setUserInfo']),
    BeforeUnload (file) {
      // console.log('file=====', file)
      if (localStorage.getItem('token')) {
        const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1)
        const whiteList = [ 'doc', 'docx', 'xls', 'xlsx',  'ppt', 'pptx', 'pdf', 'bmp', 'gif', 'jpg', 'jpeg', 'png', 'tiff']
        if (whiteList.indexOf(fileSuffix) === -1) {
          this.$message.error('您上传的格式不支持')
          this.$refs.upload.abort()
          return false
        } else {
          this.Uploading = true
          this.FileUploaded = false
          this.percentage = 0
        }
      } else {
        this.$refs.upload.abort()
        this.$router.push('/login')
      }
    },
    // OnProgress (event) {
    //   this.percentage = Math.ceil(event.percent)
    // },
    handleProgress (event, file, fileList) {
      console.log('event', event)
      console.log('file', file)
      console.log('fileList', fileList)

    },
    UploadChange (file) {
      if (localStorage.getItem('token')) {
        var obj = {
          name: file.name,
          pages: '',
          file: file,
          ocrLanguage: 'ChinesePRC',
          translateLanguage: 'en',
          translate_mode: 'General',
          outFileType: 'docx',
          translate_table: true,
          uploadQi: false,
          file_state: null,
          FileStatus: '',
          out_file: '',
        }
        this.tableData.push(obj)
      } else {
        // this.$refs.upload.abort()
        this.$router.push('/login')
        if (!this.MessageDataMain.globalVarMess) {
          this.MessageDataMain.globalVarMess = true
          this.$message({
            message: '请登录后再进行翻译',
            type: 'error',
            onClose: this.allCloseMesge
          })
        }
        
      }
      // if (file.percentage == 100) {
      //   this.FileUploaded = true
      //   this.tableData.push(obj)
      // }
      // console.log('table', this.tableData)
    },
    handleRemove (file, fileList) {
      console.log(file);
      console.log('fileList', fileList);
      this.tableData = fileList
    },
    submitUpload () {
      this.$refs.upload.submit()
    },
    // handleExceed (fileList) {
    //   this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${fileList.length} 个文件`);
    // },
    filterSectorChange (row, value) {
      if (value == 'General') {
        row.isDisabled = false
      } else {
        row.isDisabled = true
      }
      if (
        value === 'electronics' ||
        value === 'finance' ||
        value === 'mechanics' ||
        value === 'medicine' ||
        value === 'novel'
      ) {
        row.ocrLanguage = 'ChinesePRC'
        row.translateLanguage = 'en'
      } else {
        row.ocrLanguage = 'English'
        row.translateLanguage = 'zh'
      }
    },
    // 上传至七牛云
    uploadQiniu (row) {
      if (this.storageFile.file_state && this.storageFile.percentage === 100) {
        this.$message.error('有文件正在翻译')
      } else {
        this.storageFile = row
        row.uploadQi = true
        clearInterval(this.timer)
        let translate_mode = ''
        if (row.translate_mode.indexOf(1)) {
          translate_mode = row.translate_mode.split(1)[0]
        } else {
          translate_mode = row.translate_mode
        }
        let newName = row.name.split('.')[0]
        let newType = row.name.split('.')[1]
        //取用户id


        let fileName = newName + '_' + this.userInfo.id + '_' + new Date().getTime() + '.' + newType
        this.fileName = fileName
        let params = JSON.stringify({
          fileName: fileName,
          ocrLanguage: row.ocrLanguage,
          outFileType: row.outFileType,
          translateLanguage: row.translateLanguage,
          translate_mode: translate_mode,
          translate_table: row.translate_table
        })
        console.log('params', params)
        getUploadFileKey(params).then((res) => {
          // console.log('res', res)
          if (res.status === 1) {
            this.qiniuToken = res.data
            this.qiniu(this.qiniuToken, row.file.raw)
          }
        })
      }
    },
    // 七牛上传封装
    qiniu (token, file) {
      var key = this.fileName
      var putExtra = {
        fname: this.fileName,
        params: {},
        mimeType: null
      }
      var _this = this
      var observable = this.$qiniu.upload(file, key, token, putExtra, null)
      var observer = {
        next(res) {
          console.log(res)
          _this.storageFile.uploadQi = true
          _this.storageFile.percentage = Math.ceil(res.total.percent)
        },
        error(err) {
          console.error(err);
        },
        complete(res) {
          _this.uploadCompleteFile = res
          _this.uploadSuccess()
        }
      }
      observable.subscribe(observer)
    },
    // 文件上传成功后操作
    uploadSuccess () {
      var key = this.uploadCompleteFile.key
      UploadSucceeded(key).then((res) => {
        if (res.status === 1) {
          this.$message.success('上传成功')
          this.storageFile.uploadQi = true
          this.getFileStatus()
        } else {
          switch (res.status) {
            case -12:
              this.$message.error('文件参数设置错误')
              break;
            case -13:
              this.$message.error('未找到用户对应的文件，文件处理失败')
              break;
            case -14:
              this.$message.error('未找到文件')
              break;
            default:
              break;
          }
        }
      })
    },
    // 获取文件状态
    getFileStatus () {
      var _this = this
      this.timer = setInterval(() => {
        getFileInfo(_this.uploadCompleteFile.key).then((res) => {
          // console.log('res', res)
          if (res.status === 1) {
            switch (res.data.file_state) {
              case 0:
                _this.storageFile.file_state = 0
                _this.storageFile.FileStatus = '创建成功'
                _this.storageFile.pages = res.data.file_pages
                console.log(0)
                break;
              case 1:
                _this.storageFile.file_state = 1
                _this.storageFile.FileStatus = '上传成功'
                _this.storageFile.pages = res.data.file_pages
                console.log(1)
                break;
              case 2:
                _this.storageFile.file_state = 2
                _this.storageFile.FileStatus = '进入处理队列'
                _this.storageFile.pages = res.data.file_pages
                console.log(2)
                break;
              case 3:
                _this.storageFile.file_state = 3
                _this.storageFile.FileStatus = '开始处理'
                _this.storageFile.pages = res.data.file_pages
                console.log(3)
                break;
              case 4:
                _this.storageFile.file_state = 4
                _this.storageFile.FileStatus = '次数不够'
                _this.storageFile.pages = res.data.file_pages
                clearInterval(_this.timer)
                _this.storageFile = {}
                console.log(4)
                break;
              case 5:
                _this.storageFile.file_state = 5
                _this.storageFile.FileStatus = '翻译模型与翻译语言不匹配'
                _this.storageFile.pages = res.data.file_pages
                clearInterval(_this.timer)
                _this.storageFile = {}
                console.log(5)
                break;
              case 6:
                _this.storageFile.file_state = 6
                _this.storageFile.FileStatus = '文件错误'
                _this.storageFile.pages = res.data.file_pages
                clearInterval(_this.timer)
                _this.storageFile = {}
                console.log(6)
                break;
              case 7:
                _this.storageFile.file_state = 7
                _this.storageFile.FileStatus = '服务器繁忙'
                _this.storageFile.pages = res.data.file_pages
                clearInterval(_this.timer)
                _this.storageFile = {}
                console.log(7)
                break;
              case 8:
                _this.storageFile.file_state = 8
                _this.storageFile.FileStatus = '下载'
                _this.storageFile.pages = res.data.file_pages
                _this.outFileName = res.data.out_file
                clearInterval(_this.timer)
                _this.storageFile = {}
                console.log(8)
                break;
            
              default:
                break;
            }
          } else {
            clearInterval(_this.timer)
            _this.storageFile = {}
          }
        })
      }, 3000)
      
    },
    // 从七牛云下载文件
    downloadQiniu (row) {
      // console.log('row', row)
      getDownloadFile(this.outFileName).then((res) => {
        // console.log('out_file', res)
        if (res.status === 1) {
          let url = res.data
          console.log('url', url)
          if (row.outFileType) {
            this.export_raw(this.outFileName, url)
          } else {
            this.downloadLink = url
            let link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download',this.outFileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          }
        } else {
          switch (res.status) {
            case -13:
              this.$message.error('未找到用户对应的文件，文件处理失败')
              break;
            case -14:
              this.$message.error('未找到文件')
              break;
          
            default:
              break;
          }
        }
      })
    },
    export_raw (name, url) {
      var oReq = new XMLHttpRequest()
      oReq.open("GET", url, true)
      oReq.responseType = "blob"
      oReq.onload = function(oEvent) {
        var content = oReq.response;
        var elink = document.createElement("a");
        elink.download = name;
        elink.style.display = "none";
        var blob = new Blob([content]);
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        document.body.removeChild(elink);
      };
      oReq.send();
    },
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }
}
</script>

