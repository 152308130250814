<template>
  <div class="order-list">
    <Header></Header>
    <div class="container tran-box">
      <el-table
        v-loading="loading"
        :data="tableData"
        style="width: 100%">
        <template slot="empty">
          <div>
            <img width="200" height="200" src="@/assets/images/no_data.png" alt="">
            <p style="text-align: center;">还没有订单哦</p>
          </div>
        </template>
        <el-table-column
          prop="order_sn"
          label="订单号">
        </el-table-column>
        <el-table-column
          prop="order_price"
          label="订单金额(元)">
          <template slot-scope="scope">
            <div>
              {{ scope.row.order_price }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="pages"
          label="购买页数(页)">
          <template slot-scope="scope">
            <div>
              {{ scope.row.pages }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="pay_type"
          label="支付方式">
          <template slot-scope="scope">
            <div class="flex-align-center">
              <img v-if="scope.row.pay_type == 'ailPay-qc_code'" width="18" height="18" src="@/assets/images/zhifubao.png" alt="">
              <img v-else width="18" height="18" src="@/assets/images/weixin.png" alt="">
              <span>{{ scope.row.pay_type == 'ailPay-qc_code' ? '支付宝' : '微信' }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="completeTime"
          label="订单状态">
          <template slot-scope="scope">
            <i v-if="scope.row.completeTime" class="el-icon-success" style="color: #67C23A"></i>
            <i v-else class="el-icon-error" style="color: #F56C6C"></i>
            <span>{{ scope.row.completeTime ? '支付成功' : '支付失败' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="create_order_time"
          label="创建时间">
          <template slot-scope="scope">
            <div>{{ scope.row.create_order_time }}</div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="pagination"
        @current-change="handleCurrentChange"
        :pager-count="11"
        :page-size="pageSize"
        layout="total, prev, pager, next"
        :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import Header from "@/components/layout/Header.vue"
import Util from "@/assets/util/util.js"
import { getOrderList } from "@/api/api.js"
export default {
  components: {
    Header
  },
  data () {
    return {
      loading: true,
      tableData: [],
      pageOffset: 1,
      pageSize: 13,
      total: 10
    }
  },
  created () {
    this.getTableData()
  },
  methods: {
    dataTable (data) {
      data.forEach((item) => {
        if (item.create_order_time) {
          var time = Math.round(Number(item.create_order_time) / 1000)
          item.create_order_time = Util.filterTimeYmdHms(time)
        }
      })
    },
    getTableData () {
      getOrderList(this.pageOffset, this.pageSize).then((res) => {
        this.loading = false
        console.log('res', res)
        if (res.status == 1) {
          var data = [...res.data.content]
          this.dataTable(data)
          this.tableData = data
          this.total = res.data.totalElements
        }
      })
    },
    handleCurrentChange (val) {
      this.pageOffset = val
      this.getTableData()
    }
  }
}
</script>