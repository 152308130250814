<template>
  <div id="app">
    <router-view/>
  </div>

</template>

<style lang="scss">
</style>



<script type="text/javascript">
(function (w, d, n, a, j) {
  w[n] = w[n] || function () {
    (w[n].a = w[n].a || []).push(arguments);
  };
  j = d.createElement("script");
  j.async = true;
  j.src = "https://kefu.ywkefu.com/static/js/ywkefu_sdk.js";
  var s = d.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(j, s);
})(window, document, "ywkf");
ywkf("config", {
  appSceneId: 136
})
</script>
