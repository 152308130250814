<template>
  <div class="pay-box">
    <div class="tab_boxs_content">
      <div class="allPayBox_special">
        <!-- <div class="tab_box">
          <ul class="tab_boxList flex-around">
            <li @click="tabClick(1)" class="tab_boxs" :class="ClassActive == 1 ? 'tab_active' : ''" ><i>VIP</i></li> 
            <li @click="tabClick(2)" class="tab_boxs" :class="ClassActive == 2 ? 'tab_active' : ''"><i>特惠套餐</i></li>
          </ul>
        </div> -->
        <div class="format_meal">
          <swiper
            class="setMeal_slider_content"
            :options="swiperOption"
            :not-next-tick="notNextTick"
          >
            <swiper-slide
              class="money_box money_box4"
              v-for="(money, index) in moneyList"
              :key="index"
              :class="[rechargeTabIndex == index ? 'recharge-tab-active' : '']"
            >
              <div @click="rechargeTab(money, index)">
                <div class="activity" v-if="money.preferential_info">
                  限时<span class="vip_font_w">{{
                    money.preferential_info
                  }}</span>
                </div>
                <div class="money_title">
                  <span>{{ money.pages }}</span
                  >页
                </div>
                <div class="price mt14">
                  ￥<span class="money_realy">{{ money.price }}</span>
                </div>
                <div class="real_money" v-if="money.original_price">
                  原价:￥{{ money.original_price }}
                </div>
                <img
                  v-if="rechargeTabIndex == index"
                  class="mark_img"
                  src="@/assets/images/check.png"
                />
              </div>
            </swiper-slide>
          </swiper>
          <div class="specialText flex-between">
            <p class="format_text">
              特惠套餐可用于文档翻译、下载译文等功能，统一按页数结算；特惠套餐有效期为2年。
            </p>
          </div>
        </div>
        <div class="format_meal_bottom flex">
          <div class="left">
            <div class="">
              <p class="pay_tetx">支付方式</p>
              <div class="flex">
                <el-radio @input="radioChange" v-model="radio" label="2" style="display: flex;align-items: center;">
                  <div class="flex-align-center">
                    <img src="@/assets/images/weixin.png" alt="" style="width:30px;height:30px; margin-right: 5px;">
                    <span>微信支付</span>
                  </div>
                </el-radio>
                <el-radio @input="radioChange" v-model="radio" label="1" style="display: flex;align-items: center;">
                  <div class="flex-align-center">
                    <img src="@/assets/images/zhifubao.png" alt="" style="width:30px;height:30px; margin-right: 5px">
                    <span>支付宝</span>
                  </div>
                </el-radio>
              </div>
              <div class="total_pay flex-end">
                总计：￥ <span class="hMeYAF">{{ totalPay }}</span>
              </div>
            </div>
          </div>
          <div class="right">
            <div v-if="weixinQrcode" class="flex-align-center flex-center pay_tetx">
              <img style="width: 30px; height: 30px;" v-if="radio == '1'" src="@/assets/images/zhifubao.png" alt="">
              <img style="width: 30px; height: 30px;" v-if="radio == '2'" src="@/assets/images/weixin.png" alt="">
              <span>请使用{{ radio == '1' ? '支付宝' : '微信' }}扫码完成支付</span>
            </div>
            <div v-else class="flex-align-center flex-center pay_tetx">
              <span>二维码加载中</span>
            </div>
            <div>
              <div class="wx-back flex-center">
                <div v-show="weixinQrcode" class="qrcode" ref="qrCodeUrl"></div>
                <div v-show="!weixinQrcode" class="loading_code">
                  <img src="@/assets/images/loading_code.png" alt="">
                </div>
              </div>
            </div>
            <div id="pay"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getPayList, wxPay, aliPay } from "@/api/api.js";
import QRCode from 'qrcodejs2'
export default {
  data() {
    return {
      ClassActive: 1,
      moneyActive: false,
      notNextTick: true,
      radio: '2',
      swiperOption: {
        slidesPerView: 4, //一次显示的数量
        direction: "horizontal", // 水平轮播
        grabCursor: true,
        setWrapperSize: true,
        autoHeight: true,
        pagination: ".swiper-pagination",
        paginationClickable: true,
        // scrollbar: ".swiper-scrollbar",
        mousewheelControl: true,
        observeParents: true,
        freeMode: true,
        spaceBetween: 30,
        // if you need use plugins in the swiper, you can config in here like this
        // 如果自行设计了插件，那么插件的一些配置相关参数，也应该出现在这个对象中，如下debugger
        debugger: true,
        // swiper callbacks
        // swiper的各种回调函数也可以出现在这个对象中，和swiper官方一样
        // onTransitionStart(swiper) {
        //   console.log(swiper);
        // },
      },
      moneyList: [],
      rechargeTabIndex: 0,
      totalPay: 15,
      weixinQrcode: '',
    };
  },
  created() {
    this.getMoneyList();
    
  },
  mounted() {
    setTimeout(() => {
      this.getPayCode(this.moneyList[0])
    }, 500)
  },
  methods: {
    tabClick(value) {
      this.ClassActive = value;
    },
    getMoneyList() {
      getPayList().then((res) => {
        if (res.status == 1) {
          this.moneyList = res.data;
        }
      })
    },
    rechargeTab (value, index) {
      this.rechargeTabIndex = index
      this.totalPay = value.price
      this.selectRow = value
      this.weixinQrcode = ''
      this.getPayCode(value)
    },
    creatQrCode () {
      this.$refs.qrCodeUrl.innerHTML = ''
      var qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: this.weixinQrcode, // 需要转换为二维码的内容
        width: 164,
        height: 164,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H
      })
      return qrcode
    },
    radioChange (value) {
      console.log('value---', value)
      this.getPayCode(this.moneyList[0])
    },
    getPayCode (row) {
      if (this.radio == '2') {
        wxPay(row.id).then((res) => {
          if (res.status == 1) {
            this.weixinQrcode = res.data.payInfo
            this.creatQrCode();
          }
        })
      } else {
        aliPay(row.id).then((res) => {
          console.log('aliPay', res)
          let divForm = document.getElementsByTagName('div')
          if (divForm.length) {
            document.body.removeChild(divForm[0])
          }
          const div = document.createElement('divform');
          div.innerHTML=res.data.payInfo;
          document.body.appendChild(div);
          document.forms[0].setAttribute('target', '_blank') 
          document.forms[0].submit()
        })
      }
    }
  },
};
</script>