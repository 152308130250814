<template>
  <div>
    <el-header class="header-nav other-header">
      <div class="main-w head-main-width flex-between flex-align-center">
        <div class="flex-align-center">
          <a href="/" class="logo">
            <img src="@/assets/images/newlogo.jpg" alt="极度翻译logo">
          </a>
          <ul class="flex-align-center nav" v-show="token">
            <li 
              v-for="(tab, index) in tabsList" 
              :key="index">
              <router-link :to="tab.path">
                {{ tab.name }}
              </router-link>
            </li>
          </ul>
        </div>
        <div class="common-head-right">
          <div class="face-box" v-if="!token">
            <router-link to="/login">
              <el-button type="primary" class="login-btn" plain round >登录 / 注册</el-button>
            </router-link>
          </div>
          <div class="personCentre personCentreNo" v-else>
            <div class="has-login-box flex-align-center">
              <img src="@/assets/images/default_head.jpg" alt="头像" class="head-face">
              <el-popover
                popper-class="user-info-more"
                placement="bottom"
                width="120"
                trigger="hover">
                <ul class="more-operate">
                  <li class="flex-between"> 
                    <span> 账号</span> {{ userInfo.account }}
                  </li>
                  <li class="flex-between"> <span> 购买翻译页数</span> {{ userInfo.buy_translate_pages }}</li>
                  <li class="flex-between"> <span> 赠送翻译页数</span> {{ userInfo.give_translate_pages }}</li>
                  <!-- <li class="flex-between"> <span> 当天消耗页数</span> {{ userInfo.day_translate_pages }}</li> -->
                  <!-- <router-link to="/fileList" class="more-operate-consum"><li> <span >文件列表</span> </li> </router-link>
                  <router-link to="/transRecord" class="more-operate-consum"><li> <span >转移记录</span> </li> </router-link>
                  <li @click="dialogVisible = true"> <span>充值中心</span></li> -->
                  <li class="flex-align-center flex-between"> 
                    <div class="operate-last" @click="refreshClick"> <span> <i class="el-icon-refresh"></i> 刷新</span> </div>
                    <div class="operate-last" @click="signoutClick"><span> <i class="el-icon-switch-button"></i> 注销</span></div>
                  </li>
                </ul>
                <el-button type="text" class="user_info_btn" slot="reference">个人中心</el-button>
              </el-popover>
            </div>
          </div>
        </div>
      </div>
    </el-header>
    <el-dialog
      :show-close="false"
      custom-class="allPayBox"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible">
      <div class="allPayBox_top" slot="title">
        <i class="el-icon-close iconcha" @click="closeDialog"></i>
        <img class="backgroundImg" src="@/assets/images/allPayBox_bkg.png" alt="头像">
        <div class="allPayBox_persionInfo ">
          <div class="headImg">
            <img class="img" src="@/assets/images/default_head.jpg" alt="头像">
          </div>
          <div class="headName">
            <span class="name">{{ userInfo.account }}</span>
            <!-- <span class="detail">{{ userInfo.vip ? userInfo.vip : '您当前还不是VIP' }}</span> -->
          </div>
        </div>
      </div>
      <PayContainer></PayContainer>
    </el-dialog>
  </div>
</template>

<script>
import { getUserProfile } from "@/api/api.js";
import PayContainer from "../pay.vue";
import { mapState, mapMutations } from 'vuex'
export default {
  components: {
    PayContainer
  },
  data() {
    return {
      token: localStorage.getItem('token'),
      // userInfo: {
      //   account: null,
      //   buy_translate_pages: null,
      //   give_translate_pages: null,
      //   day_translate_pages: null
      // },
      dialogVisible: false,
      tabsList: [
        {
          path: '/',
          name: '文档翻译',
        },
        {
          path: '/fileList',
          name: '文件列表',
        },
        {
          path: '/transRecord',
          name: '转移记录',
        },
        {
          path: '/order',
          name: '订单列表',
        },
        {
          path: '/pay',
          name: '充值中心',
        },
      ]
    }
  },
  computed: {
    ...mapState(['userInfo'])
  },
  created () {
  },
  mounted () {
/*    if (localStorage.getItem('userInfo')) {
      this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    }*/
    // console.log(this.userInfo)
  },
  methods: {
    ...mapMutations(['setUserInfo']),
    signoutClick () {
      localStorage.removeItem('token')
      localStorage.removeItem('userInfo')
      this.token = ''
      this.$router.push({
        path: "/"
      })
    },
    refreshClick () {
      getUserProfile().then((res) => {
        console.log('res', res)
        if (res.status === 1) {
          this.userInfo = res.data
          this.token = res.data.token
          this.setUserInfo(res.data)
          localStorage.setItem('token', res.data.token);
          // localStorage.setItem('userInfo', JSON.stringify(res.data));
        }
      })
    },
    closeDialog () {
      this.dialogVisible = false
    }
  },
}
</script>

