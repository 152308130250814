import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex)

const store = new Vuex.Store({

    state: {
        userInfo: {
            account: '',
            buy_translate_pages:'',
            day_translate_pages: '',
            give_translate_pages:'',
            id: '',
            token: ''
        }
    },

    // getters: {
    //   getMessage(state) {
    //     // 获取修饰后的name，第一个参数state为必要参数，必须写在形参上
    //     return `hello${state.name}`;
    //   },
    // },
    mutations: {
        setUserInfo(state, payload) {
            state.userInfo = payload
        }
    },
    plugins: [createPersistedState({
        key: 'userInfo',
        paths: ['userInfo']
    })]
});


export default store
