<template>
  <div class="trans">
    <Header></Header>
    <div class="container tran-box">
      <el-button @click="openDialog" class="trans_button" type="primary" plain>转移购买页</el-button>
      <el-table 
        v-loading="loading"
        :data="tableData"
        style="width: 100%">
        <template slot="empty">
          <div>
            <img width="200" height="200" src="@/assets/images/no_data.png" alt="">
            <p style="text-align: center;">还没有转增记录哦</p>
          </div>
        </template>
        <el-table-column
          prop="account"
          label="转出账户">
          <template slot-scope="scope">
            <div> 
              <!-- <i class="el-icon-sold-out"></i> -->
              {{ scope.row.account }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="transfer_account"
          label="转入账户">
          <template slot-scope="scope">
            <div>
              <!-- <i class="el-icon-sell"></i> -->
              {{ scope.row.transfer_account }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="pages"
          label="转移页数">
          <template slot-scope="scope">
            <div>{{ scope.row.pages }}页</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="transfer_time"
          label="转移时间">
          <template slot-scope="scope">
            <div>{{ scope.row.transfer_time }}</div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="pagination"
        @current-change="handleCurrentChange"
        :pager-count="11"
        :page-size="pageSize"
        layout="total, prev, pager, next"
        :total="total">
      </el-pagination>
    </div>
    <el-dialog
      :show-close="false"
      custom-class="transPagesBox"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible">
      <div class="transPages_top" slot="title">
        <div class="flex-between flex-align-center">
          <span>转移购买页</span>
          <i class="el-icon-close iconcha" @click="closeDialog"></i>
        </div>
      </div>
      <div class="transPages_con">
        <el-form 
          :rules="rules"
          :model="transValidateForm" 
          ref="transValidateForm" 
          label-width="100px" 
          class="demo-ruleForm">
          <el-form-item label="当前账户" prop="name">
            <p>{{ userInfo.account }}</p>
          </el-form-item>
          <el-form-item label="转出账户" prop="transferAccount">
            <el-input 
              oninput="value=value.replace(/[^\d]/g,'')"
              v-model.trim="transValidateForm.transferAccount"
              autocomplete="off">
            </el-input>
          </el-form-item>
          <el-form-item label="转出页数" prop="pages">
            <el-input 
              oninput="value=value.replace(/[^\d]/g,'')"
              v-model.trim="transValidateForm.pages"
              autocomplete="off">
            </el-input>
          </el-form-item>
          <div class="pages_tips">
            可转出页数：<span>{{ userInfo.buy_translate_pages }} 页</span>
            <el-button class="out-button" type="text" @click="clickTurnOut">全部转出</el-button>
          </div>
          <el-form-item>
            <div class="transPages_bottom flex-end">
              <el-button @click="closeDialog()">取消</el-button>
              <el-button type="primary" @click="submitForm()">提交</el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { TransRecord, getUserProfile, transferPage } from "@/api/api.js";
import Header from "@/components/layout/Header.vue";
import Util from "@/assets/util/util.js";
import { mapState, mapMutations } from 'vuex'
export default {
  components: {
    Header
  },
  data() {
    const validatePhone = (rule, value, callback) => {
      if (/^[1-9]\d*$/.test(value) == false ) {
        callback(new Error("转出账户格式不正确，请重新输入!"))
      } else if (value.length != 11) {
        callback(new Error("转出账户格式不正确，请重新输入!"))
      } else {
        callback()
      }
    }
    const overPage = (rule, value, callback) => {
      if (value > this.userInfo.buy_translate_pages ) {
        callback(new Error("转出页数超出范围!"))
      } else {
        callback()
      }
    }
    return {
      tableData: [],
      pageOffset: 1,
      pageSize: 10,
      loading: true,
      total: 10,
      dialogVisible: false,
      // userInfo: {},
      rules: {
        transferAccount: [
          { required: true, message: '请输入转出账户', trigger: 'blur' },
          { validator: validatePhone, required: true, trigger: ['change', 'blur'] }
        ],
        pages: [
          { required: true, message: '请输入转出页数', trigger: 'blur' },
          { validator: overPage, required: true, trigger: ['change', 'blur'] }
        ]
      },
      transValidateForm: {
        transferAccount: '',
        pages: ''
      }
    }
  },
  computed: {
    ...mapState(['userInfo'])
  },
  created () {
    this.getTableData()
  },
  methods: {
    ...mapMutations(['setUserInfo']),
    dataTable (data) {
      data.forEach((item) => {
        if (item.transfer_time) {
          var time = Math.round(Number(item.transfer_time) / 1000)
          item.transfer_time = Util.filterTimeYmdHms(time)
        }
      })
    },
    getTableData () {
      this.loading = true
      TransRecord(this.pageOffset, this.pageSize).then((res) => {
        console.log('res', res)
        this.loading = false
        if (res.status === 1) {
          var data = [...res.data.content]
          this.dataTable(data)
          this.tableData = data
          this.total = res.data.totalElements
        }
      })
    },
    handleCurrentChange (val) {
      this.pageOffset = val
      this.getTableData()
    },
    // 获取用户信息
    getUserInfo () {
      getUserProfile().then((res) => {
        console.log('res', res)
        if (res.status === 1) {
          // this.userInfo = res.data
          this.setUserInfo(res.data)
          localStorage.setItem('token', res.data.token)
          // localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
        }
      })
    },
    openDialog () {
      this.dialogVisible = true
      this.getUserInfo()
    },
    closeDialog () {
      this.dialogVisible = false
    },
    clickTurnOut () {
      this.transValidateForm.pages = this.userInfo.buy_translate_pages
    },
    submitForm () {
      this.$refs.transValidateForm.validate((valid) => {
        if (valid) {
          var pages = this.transValidateForm.pages
          var account = this.transValidateForm.transferAccount
          transferPage(pages, account).then((res) => {
            if (res.status == 1) {
              this.$message.success('转移成功')
              this.pageOffset = 1
              this.getTableData()
              this.getUserInfo()
              this.dialogVisible = false
            } else {
              switch (res.status) {
                case -15:
                  this.$message.error('次数转移失败:请检查对方账号是否存在,或页数是否够用')
                  break;
                case -16:
                  this.$message.error('没有记录')
                  break;
              
                default:
                  this.$message.error(res.info)
                  break;
              }
            }
          })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.trans_button {
  position: absolute;
  top: 10px;
  right: 20px;
}
.transPagesBox {
  .transPages_top {
    height: 60px;
    padding: 16px;
    box-sizing: border-box;
    font-size: 20px;
    span {
      font-size: 16px;
    }
  }
  .transPages_bottom {
    padding: 0 20px 20px;
  }
  .el-input {
    width: 300px;
  }
  .pages_tips {
    margin-left: 100px;
    margin-top: -15px;
  }
}
.out-button.el-button {
  margin-left: 10px;
  .el-button--text:focus, .el-button--text:hover {
    opacity: 1
  }
}
</style>