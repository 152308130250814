<template>
  <el-form 
    :model="codeForm" 
    :rules="codeRules" 
    ref="codeForm" 
    class="input-box">
    
    <el-form-item 
      prop="phone">
      <el-input 
        placeholder="请输入正确的手机号"
        prefix-icon="el-icon-mobile-phone" 
        v-model="codeForm.phone" 
        autocomplete="off">
      </el-input>
    </el-form-item>
    <el-form-item prop="code">
      <el-input 
        placeholder="输入验证码"
        prefix-icon="el-icon-message" 
        v-model.trim="codeForm.code" 
        oninput="value=value.replace(/[^\d]/g,'')"
        autocomplete="off">
        <i
          v-if="codeFlag"
          class="getyzm"
          slot="suffix"
          @click="getCodeClick">
          {{ code }}
        </i>
        <span v-else
          class="getyzm second"
          slot="suffix"
          >{{ code }}</span>
      </el-input>
    </el-form-item>
    <el-form-item>
      <el-button class="common-btn" type="primary" @click="submitForm()">确认登录</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { getSmsCode, VerificationCodeLogin, getUserProfile } from "@/api/api.js";
export default {
  data() {
    const validatePhone = (rule, value, callback) => {
      if (/^[1-9]\d*$/.test(value) == false ) {
        callback(new Error("手机格式不正确，请重新输入!"))
      } else if (value.length != 11) {
        callback(new Error("手机格式不正确，请重新输入!"))
      } else {
        callback()
      }
    }
    return {
      codeFlag: true,
      codeForm: {
        phone: null,
        code: null
      },
      codeRules: {
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: validatePhone, required: true, trigger: ['change', 'blur'] }
        ],
        code: [
          { required: true, message: '请输入验证码', trigger: ['change', 'blur'] }
        ]
      },
      code: '获取验证码'
    }
  },
  methods: {
    getCodeClick () {
      var time = 120
      var _this = this
      if (this.codeForm.phone.toString().length == 11) {
        this.codeFlag = false
        let params = {
          deviceID: localStorage.getItem('visitorId'),
          phoneNumber: this.codeForm.phone.toString()
        }
        getSmsCode(params).then((res) => {
          this.$message.success('验证码已发送')
          this.codeForm.code = res.data
        })
        let timer = setInterval(() => {
          if (time-- > 1) {
            _this.code = time + 's'
          } else {
            _this.code = "获取验证码"
            _this.codeFlag = true
            clearInterval(timer)
          }
        }, 1000)
      }
    },
    submitForm () {
      this.$refs.codeForm.validate((valid) => {
        if (valid) {
          VerificationCodeLogin(localStorage.getItem('visitorId'), this.codeForm.phone, this.codeForm.code ).then((res) => {
            if (res.status == 1) {
              localStorage.setItem('token', res.data)
              this.$message.success('登录成功')
              getUserProfile().then((user) => {
                if (user.status == 1) {
                  const userInfo = JSON.stringify(user.data)
                  localStorage.setItem('token', res.data.token)
                  localStorage.setItem('userInfo', userInfo)
                }
              })
              this.$router.push({
                path: "/"
              })
            } else {
              switch (res.status) {
                case -12:
                  this.$message({
                    message: '缺少密码',
                    type: 'error'
                  })
                  break;
                case -13:
                  this.$message({
                    message: '账号未注册，请先注册',
                    type: 'error'
                  })
                  this.$router.push('/signup')
                  break;
                case -14:
                  this.$message({
                    message: '密码错误',
                    type: 'error'
                  })
                  break;
                case -15:
                  this.$message({
                    message: '手机格式错误',
                    type: 'error'
                  })
                  break;
                case -16:
                  this.$message({
                    message: '验证码错误',
                    type: 'error'
                  })
                  break;
              
                default:
                  break;
              }
            }
          })
        }
      })
    }
  }
}
</script>