<template>
  <div class="pay">
    <Header></Header>
    <div class="content_title flex-center flex-align-center">
      <p>充值中心</p>
    </div>
    <div class="allPayBox_special">
      <div class="format_meal flex-center">
        <swiper
          :options="swiperOption"
          :not-next-tick="notNextTick"
          class="setMeal_slider_content">
          <swiper-slide
            v-for="(money, index) in moneyList"
            :key="index">
            <div 
              class="money_box money_box4"
              :class="[rechargeTabIndex == index ? 'recharge-tab-active' : '']"
             @click="rechargeTab(money, index)">
              <div class="activity" v-if="money.preferential_info">
                <span class="vip_font_w">{{
                  money.preferential_info
                }}</span>
              </div>
              <div class="money_title">
                <span>{{ money.pages }}</span
                >页
              </div>
              <div class="price flex-center mt14">
                ￥<span class="money_realy">{{ money.price }}</span>
              </div>
              <div class="real_money" v-if="money.original_price">
                原价:￥{{ money.original_price }}
              </div>
              <img
                v-if="rechargeTabIndex == index"
                class="mark_img"
                src="@/assets/images/check.png"
              />
            </div>
            <!-- <el-button @click="rechargeTab(money, index)" class="pay-button" type="primary" >立即充值</el-button> -->
          </swiper-slide>
          <!-- <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div> -->
        </swiper>
        <div class="swiper-pagination"></div>
      </div>
      <div class="format_meal_bottom">
        <div class="content">
          <p class="pay_text">支付方式</p>
          <div class="flex">
            <el-radio
              @input="radioChange"
              v-model="radio"
              label="2"
              style="display: flex; align-items: center"
            >
              <div class="flex-align-center">
                <img
                  src="@/assets/images/wxLogo.png"
                  alt=""
                  style="width: 120px; height: 50px; margin-right: 5px"
                />
                <!-- <span>微信支付</span> -->
              </div>
            </el-radio>
            <el-radio
              @input="radioChange"
              v-model="radio"
              label="1"
              style="display: flex; align-items: center"
            >
              <div class="flex-align-center">
                <img
                  src="@/assets/images/zfLogo.png"
                  alt=""
                  style="width: 120px; height: 50px; margin-right: 5px"
                />
                <!-- <span>支付宝</span> -->
              </div>
            </el-radio>
          </div>
          <el-button
            @click="payButton"
            class="pay-button"
            type="primary"
            >立即充值</el-button
          >
          <!-- <div class="total_pay">
          <span>总计：</span>  ￥ <span class="hMeYAF">{{ totalPay }}</span>
          </div> -->
        </div>
      </div>
    </div>
    <!-- 微信支付弹窗 -->
    <el-dialog
      :show-close="false"
      custom-class="wxPayBox"
      :close-on-click-modal="false"
      :visible.sync="wxDialogVisible">
      <div class="wx-img">
        <img 
          class="wx-close"
          @click="closeDialog" 
          src="@/assets/images/close-wx.png" alt="">
        <div class="wx-back">
          <div style="padding-top: 40px">
            <img
              src="@/assets/images/wx-pay-icon.png"
              alt=""
              class="wx-pay-icon"
            />
          </div>
          <div v-show="weixinQrcode" class="qrcode" ref="qrCodeUrl"></div>
          <div v-show="!weixinQrcode" class="loading_code">
            <img src="@/assets/images/loading_code.png" alt="">
          </div>
          <div class="sxx"> {{ weixinQrcode ? '扫码支付'+ totalPay + '元' : '二维码加载中' }}  </div>
        </div>
        <div class="wx-already-pay">
          <div style="padding-top: 20px">
            <div @click="alreadyPay" class="pay-btn">已完成付款</div>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 支付宝支付弹窗 -->
    <el-dialog custom-class="aliDialog" title="支付宝支付" :visible.sync="AlidialogVisible">
      <div class="successDialog">
        <p class="title">请您在新打开的页面上完成付款</p>
        <p>付款完成前请不要关闭此窗口</p>
        <p>完成付款后请点击下面的按钮</p>
      </div>
      <div class="successBtn flex-end">
        <el-button type="primary" @click="alreadyPay" plain>已完成付款</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Header from "@/components/layout/Header.vue";
import { getPayList, wxPay, aliPay, getOrdeInfo } from "@/api/api.js";
import QRCode from "qrcodejs2";
export default {
  components: {
    Header,
  },
  data() {
    return {
      notNextTick: true,
      radio: "2",
      swiperOption: {
        slidesPerView: 6, //一次显示的数量
        direction: "horizontal", // 水平轮播
        grabCursor: true,
        setWrapperSize: true,
        autoHeight: true,
        pagination: ".swiper-pagination",
        paginationClickable: true,
        // scrollbar: ".swiper-scrollbar",
        mousewheelControl: true,
        observeParents: true,
        freeMode: true,
        spaceBetween: 30,
        // if you need use plugins in the swiper, you can config in here like this
        // 如果自行设计了插件，那么插件的一些配置相关参数，也应该出现在这个对象中，如下debugger
        debugger: true,
        // swiper callbacks
        // swiper的各种回调函数也可以出现在这个对象中，和swiper官方一样
        // onTransitionStart(swiper) {
        //   console.log(swiper);
        // },
      },
      moneyList: [
        {
          preferential_info: '',
          pages: 20,
          price: 20,
          original_price: null
        },
        {
          preferential_info: '',
          pages: 'Null',
          price: 20,
          original_price: null
        },
        {
          preferential_info: '',
          pages: 'Null',
          price: 20,
          original_price: null
        },
        {
          preferential_info: '',
          pages: 'Null',
          price: 20,
          original_price: null
        }
      ],
      rechargeTabIndex: 0,
      weixinQrcode: "",
      totalPay: '',
      selectPayCode: {},
      rechargeTabSelect: false,
      wxDialogVisible: false,
      AlidialogVisible: false,
      orderID: ''
    };
  },
  created() {
    this.getMoneyList();
  },
  methods: {
    getMoneyList() {
      getPayList().then((res) => {
        if (res.status == 1) {
          this.moneyList = res.data;
          this.selectPayCode = this.moneyList[0]
          this.totalPay = this.moneyList[0].price
        }
      });
    },
    rechargeTab(value, index) {
      this.rechargeTabIndex = index;
      this.rechargeTabSelect = true;
      this.selectPayCode = value
      this.totalPay = value.price;
      this.selectRow = value;
      this.weixinQrcode = "";
      // this.getPayCode(value)
    },
    radioChange(value) {
      console.log("value---", value);
      // this.getPayCode(this.moneyList[0]);
    },
    creatQrCode() {
      this.$refs.qrCodeUrl.innerHTML = "";
      var qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: this.weixinQrcode, // 需要转换为二维码的内容
        width: 180,
        height: 180,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });
      return qrcode;
    },
    getPayCode(row) {
      if (this.radio == "2") {
        wxPay(row.id).then((res) => {
          if (res.status == 1) {
            this.orderID = res.data.orderNo
            this.weixinQrcode = res.data.payInfo;
            this.creatQrCode();
          }
        });
      } else {
        aliPay(row.id).then((res) => {
          if (res.status == 1) {
            this.orderID = res.data.orderNo
            let divForm = document.getElementsByTagName("divform");
            if (divForm.length) {
              document.body.removeChild(divForm[0]);
            }
            const div = document.createElement("divform");
            div.innerHTML = res.data.payInfo;
            document.body.appendChild(div);
            document.forms[0].setAttribute("target", "_blank");
            document.forms[0].submit();
          }
        });
      }
    },
    payButton () {
      if (this.radio == '2') {
        this.wxDialogVisible = true
      } else {
        this.AlidialogVisible = true
      }
      this.getPayCode(this.selectPayCode)
    },
    closeDialog () {
      this.wxDialogVisible = false
    },
    alreadyPay () {
      getOrdeInfo(this.orderID).then((res) => {
        console.log('res', res)
        if (res.status == 1) {
          if (res.data.completeTime) {
            this.$message.success('支付成功')
            this.wxDialogVisible = false
            this.AlidialogVisible = false
          } else {
            this.$message.error('支付失败')
          }
        } 
      })
    }
  }
}
</script>


<style lang="scss" scoped>
.content_title {
  height: 154px;
  font-size: 40px;
  background-color: #fff;
  margin: 0 auto;
  background: url(@/assets/images/paycenter.png) no-repeat;
  background-size: cover;
  background-position: center 0;
  p {
    color: #0a1e51;
    font-weight: 600;
    letter-spacing: 20px
  }
}
.pay-button {
  margin: 20px 0;
  margin-top: 50px;
  display: block;
  width: 200px;
}
.wx-img .wx-close {
  position: absolute;
  right: -70px;
  top: -50px;
  width: 43px;
  height: 43px;
  cursor: pointer;
}
.wx-img .wx-back .wx-pay-icon {
  width: 197px;
  height: 44px;
  display: block;
  margin: auto;
}
.qrcode,.loading_code {
  width: 180px;
  height: 180px;
  padding: 10px;
  background-color: #fff;
  margin: 0 auto;
  margin-top: 40px;
  border-radius: 6px 6px 0 0;
}
.loading_code img{
  width: 180px;
  height: 180px;
}
.wx-img .wx-back .sxx {
  width: 200px;
  height: 30px;
  margin: auto;
  text-align: center;
  background: #fff;
  line-height: 20px;
  font-size: 22px;
  color: #000;
}
.wx-img .wx-already-pay {
  position: absolute;
  bottom: 0;
  background: #fff;
  width: 100%;
  height: 80px;
  border-radius: 0 0 40px 40px;
}
.wx-img .wx-already-pay .pay-btn {
    text-align: center;
    background: #22ac38;
    border-radius: 4px;
    width: 120px;
    height: 40px;
    font-size: 14px;
    color: #fff;
    line-height: 40px;
    margin: auto;
    cursor: pointer;
}
.successDialog {
  min-height: 270px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  .title {
    height: 45px;
    line-height: 45px;
    color: #3f66ff;
    font-size: 18px;
    font-family: PingFangSC-Medium;
    font-weight: 500;
    text-indent: 40px;
  }
}
.successBtn {
  padding-right: 40px;
  padding-bottom: 50px;
}
</style>