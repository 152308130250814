<template>
  <div class="login-content" style="" id="forgot-main">
    <div class="content-title">忘记密码</div>
    <el-form 
      :model="forgotForm" 
      :rules="forgotRules" 
      ref="forgotForm" 
      class="input-box">
      <el-form-item 
        prop="phone">
        <el-input 
          placeholder="请输入正确的手机号"
          prefix-icon="el-icon-mobile-phone" 
          v-model="forgotForm.phone" 
          autocomplete="off">
        </el-input>
      </el-form-item>
      <el-form-item prop="code">
        <el-input 
          placeholder="输入验证码"
          prefix-icon="el-icon-message" 
          v-model.trim="forgotForm.code" 
          oninput="value=value.replace(/[^\d]/g,'')"
          autocomplete="off">
          <i
            v-if="codeFlag"
            class="getyzm"
            slot="suffix"
            @click="getCodeClick">
            {{ code }}
          </i>
          <span v-else
            class="getyzm second"
            slot="suffix"
            >{{ code }}</span>
        </el-input>
      </el-form-item>
      <el-form-item prop="pass">
        <el-input 
          placeholder="输入不少于6位数的密码"
          prefix-icon="el-icon-lock" 
          type="password" 
          v-model="forgotForm.pass" 
          autocomplete="off">
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button class="common-btn" type="primary" @click="submitForm()">确认登录</el-button>
      </el-form-item>
    </el-form>

    <div class="go-login">
      <el-button type="text" class="common-btn2"  @click="loginClick">已有账号，立即登录</el-button>
    </div>
  </div>
</template>
<script>
import { getSmsCode, RetrievePassword } from "@/api/api.js";
export default {
  data() {
    const validatePhone = (rule, value, callback) => {
      if (/^[1-9]\d*$/.test(value) == false ) {
        callback(new Error("手机格式不正确，请重新输入!"))
      } else if (value.length != 11) {
        callback(new Error("手机格式不正确，请重新输入!"))
      } else {
        callback()
      }
    }
    const validatePass = (rule, value, callback) => {
      if (value.length < 6) {
        callback(new Error("请输入不少于6位数的密码"))
      } else {
        callback()
      }
    }
    return {
      forgotForm: {
        phone: null,
        code: null,
        pass: ''
      },
      forgotRules: {
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: validatePhone, required: true, trigger: ['change', 'blur'] }
        ],
        code: [
          { required: true, message: '请输入验证码', trigger: ['change', 'blur'] }
        ],
        pass: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { validator: validatePass, required: true, trigger: ['change', 'blur'] }
        ]
      },
      codeFlag: true,
      code: '获取验证码',
    }
  },
  methods: {
    getCodeClick () {
      var time = 60
      var _this = this
      if (this.forgotForm.phone.toString().length == 11) {
        this.codeFlag = false
        let params = {
          deviceID: localStorage.getItem('visitorId'),
          phoneNumber: this.forgotForm.phone.toString()
        }
        getSmsCode(params).then((res) => {
          this.$message.success('验证码已发送')
          this.forgotForm.code = res.data
        })
        let timer = setInterval(() => {
          if (time-- > 1) {
            _this.code = time + 's'
          } else {
            _this.code = "获取验证码"
            _this.codeFlag = true
            clearInterval(timer)
          }
        }, 1000)
      }
    },
    submitForm () {
      this.$refs.forgotForm.validate((valid) => {
        if (valid) {
          let deviceID = localStorage.getItem('visitorId')
          let smsCode = this.forgotForm.code
          let params = JSON.stringify({
            account: this.forgotForm.phone,
            password: this.forgotForm.pass
          })
          RetrievePassword(deviceID, smsCode, params ).then((res) => {
            console.log('res', res)
            if (res.status == 1) {
              this.$message.success('密码修改成功，请登录')
              localStorage.setItem('token', res.data)
              this.$emit('loginClick')
            } else {
              switch (res.status) {
                case -12:
                  this.$message({
                    message: '缺少密码',
                    type: 'error'
                  })
                  break;
                case -13:
                  this.$message({
                    message: '账号未注册',
                    type: 'error'
                  })
                  break;
                case -14:
                  this.$message({
                    message: '密码错误',
                    type: 'error'
                  })
                  break;
                case -15:
                  this.$message({
                    message: '手机格式错误',
                    type: 'error'
                  })
                  break;
                case -16:
                  this.$message({
                    message: '验证码错误',
                    type: 'error'
                  })
                  break;
              
                default:
                  break;
              }
            }
          })
        }
      })
      // this.$router.push({
      //   path: "/"
      // })
    },
    loginClick () {
      this.$emit('loginClick')
    }
  }
}
</script>