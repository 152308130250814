const Utils = {
  filterTimeYmdHms(value) { // 过滤时间戳，返回值yyyy-mm-dd ss
    if (!value) {
      return value;
    }
    const date = new Date(value * 1000);
    const y = 1900 + date.getYear();
    const m = `0${date.getMonth() + 1}`;
    const d = `0${date.getDate()}`;
    const hh = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
    const mm = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
    const ss = date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds();
    const val = `${y}-${m.substring(m.length - 2, m.length)}-${d.substring(d.length - 2, d.length)}  ${hh}:${mm}:${ss}`;
    return val;
  }
}

export default Utils