import api from './exception.js';

// export const getTestApi = () => api.post('/test')

// export const getXXX = (params) => api.get(`/apigb/v1/component`, { params})
// export const postXXX = (params) => api.post(`/apigb/v1/component/update-info`, params)

// 请求手机验证码
export const getSmsCode = (params) => api.get('/wrste/api/translate_document/get_sms_code', { params })

// 注册
export const Register = (deviceID, smsCode, params) => api.post('/wrste/api/translate_document/register?device_id='+ deviceID +'&smsCode='+ smsCode +'', params)

// 登录
export const Login = (params) => api.post('/wrste/api/translate_document/login', params)

// 验证码登录
export const VerificationCodeLogin = (deviceID, phoneNumber, smsCode ) => api.post('/wrste/api/translate_document/verificationCodeLogin?deviceID='+ deviceID + '&phoneNumber='+ phoneNumber +'&smsCode='+ smsCode +'')

// 找回密码
export const RetrievePassword = (deviceID, smsCode, params) => api.post('/wrste/api/translate_document/retrieve_password?device_id='+ deviceID +'&smsCode='+ smsCode +'', params)

// 获取用户资料
export const getUserProfile = () => api.get('/wrste/api/translate_document/getUserInfo')

// 获取文件上传key
export const getUploadFileKey = (params) => api.post('/wrste/api/translate_document/file/getUploadFileKey', params)

// 获取文件的信息和状态
export const getFileInfo = (fileName) => api.get('/wrste/api/translate_document/file/getFileInfo?fileName=' + fileName)

// 根据文件名获取下载地址
export const getDownloadFile = (fileName) => api.get('/wrste/api/translate_document/file/authorizeFileUrl?fileName=' + fileName)

// 获取文件列表
export const findUserFileList = (pageOffset, pageSize) => api.get('/wrste/api/translate_document/file/findUserFileList?pageOffset=' + pageOffset + '&pageSize='+ pageSize)

// 根据文件id删除文件
export const deleteUserFile = (fileId, token) => api.delete('/wrste/api/translate_document/file/deleteUserFile?fileId=' + fileId + '&token=' + token )

// 文件上传成功后的操作
export const UploadSucceeded = (fileName) => api.get('/wrste/api/translate_document/file/UploadSucceeded?fileName=' + fileName)

// 价格列表
export const getPayList = () => api.get('/wrste/api/translate_document_pay/getPayList')

// 微信支付
export const wxPay = (pay_id) => api.get('/wrste/api/translate_document_pay/wxPayNative?pay_id='+pay_id)

// 支付宝支付
export const aliPay = (pay_id) => api.get('/wrste/api/translate_document_pay/aliQcCodePay?pay_id='+pay_id)

// 订单列表
export const getOrderList = (pageOffset, pageSize) => api.get('/wrste/api/translate_document_pay/findUserOrdes?pageOffset='+pageOffset+'&pageSize='+ pageSize)

// 获取订单信息
export const getOrdeInfo = (order_sn) => api.get('/wrste/api/translate_document_pay/getOrdeInfo?order_sn='+order_sn)

// 转移记录
export const TransRecord = (pageOffset, pageSize) => api.get('/wrste/api/translate_document/findTransRecord?pageOffset=' + pageOffset + '&pageSize='+ pageSize)

// 转移页数
export const transferPage = (pages, account) => api.get('/wrste/api/translate_document/transferBuyPages?pages='+pages+'&transferAccount='+account)


